
import { QCard, QCardSection, QDialog, QSpinner } from 'quasar'
import { dateUtils, FormViewer, LanguagePicker } from '@parantion/components'
import packageJson from '../../package.json'
import axios from 'axios'

enum FormErrors {
  FORM_CLOSED = 'apiAccount.link_form_status close',
  FORM_START_DATE = 'apiAccount.link_form_date_start',
  FORM_END_DATE = 'apiAccount.link_form_date_end',
  GRANTED_MAX_FORM = 'apiAccount.link_parameter_wrong form_nonamed_granted_max',
  GRANTED_MAX_SUBJECT = 'apiAccount.link_parameter_wrong subject_nonamed_granted_max'
}

export default {
  name: 'LayoutDefault',

  components: {
    LanguagePicker,
    QCard,
    FormViewer,
    QSpinner,
    QCardSection,
    QDialog
  },

  computed: {
    form () {
      return this.$store.getters['parantion/template/content']
    },
    token () {
      return this.$store.state.parantion.template.viewer.surveyCredentials.token
    }
  },

  watch: {
    token (newVal) {
      if (!newVal && !this.startDate && !this.endDate) {
        this.$refs.dialog.show()
      } else {
        this.$refs.dialog.hide()
      }
    }
  },

  data () {
    return {
      showIsEmptyMessage: false,
      MaxRespondentsReached: false,
      MaxRespondentsReachedMessage: this.$t('formviewer.home.max_respondents_reached_message.popup'),
      maxReached: false,
      isLoading: false,
      startDate: null,
      endDate: null,
      formClosed: false,
      showDatePassed: false,
      showDateNotStarted: false,
      showFormNoNamedMax: false,
      showSubjectNoNamedMax: false,
      showAskPin: false,
      showResetPin: false,
      ticket: '',
      pin: '',
      disablePinInput: false,
      pinDialogTitle: '',
      pinDialogMessage: '',
      pinDialogAction: '',
      decodedMessages: [],
      retryCount: null
    }
  },

  async created () {
    this.setLanguage()
    this.$store.dispatch('parantion/error/registerTenantInformation', {
      tenantName: packageJson.name,
      tenantVersion: packageJson.version
    })
    this.isLoading = true

    const id = this.$router.currentRoute.query.id
    const form = this.$router.currentRoute.query.form
    const ticket = this.$router.currentRoute.query.ticket
    const token = this.$router.currentRoute.query.token
    const db = this.$router.currentRoute.query.db

    if (!id && !form && !ticket && !token) {
      setTimeout(() => {
        this.isLoading = false
        this.showIsEmptyMessage = true
      }, 1500)
      return
    }

    // Interceptor for Status 503
    axios.interceptors.response.use(response => response, async (error) => {
      if (error?.response?.status === 503) { // can be undefined if api-call is canceled, because then a response will never arrive
        this.isLoading = false
        this.MaxRespondentsReached = true
        const directusUrl = this.$store.getters['parantion/getDirectusUrl']
        const organisation = (await (await fetch(`${directusUrl}/items/organisation?filter[organisation_name][eq]=${this.$store.state.parantion.database}`)).json()).data[0] || {}
        const orgSettingsOverride = (await (await fetch(`${directusUrl}/items/organisation_settings?filter[organisation][eq]=${organisation.id}`)).json()).data[0] || {}
        if (orgSettingsOverride && orgSettingsOverride.extenden_config && orgSettingsOverride.extenden_config.max_respondents_reached_message) {
          this.MaxRespondentsReachedMessage = orgSettingsOverride.extenden_config.max_respondents_reached_message
        }
      }
      return Promise.reject(error)
    })

    this.showIsEmptyMessage = false

    if (id) {
      await this.setFormById(id)
    }

    if (form) {
      await this.setFormByHash(form)
    }

    if (ticket) {
      await this.setFormByTicket(ticket)
    }

    if (token) {
      await this.setFormByToken(token, db)
    }

    const formVars = this.$store.state.parantion.template.viewer.formVars
    if (formVars.frm_progress === 'progress_done') this.$store.commit('parantion/template/designer/SET_VIEWMODE', 3 /* readonly */)

    this.getFormStatus()
    this.isLoading = false
  },

  methods: {
    setLanguage () {
      this.$setLocale('language_nl')
    },

    async setFormByToken (token, db) {
        const response = await this.$store.dispatch('parantion/user/getUserOfTokenAndDB', { token: token, db: db })
        if (!response) {
          this.$refs.dialog.show()
          this.showIsEmptyMessage = true
          this.isLoading = false
        } else {
          await this.$store.dispatch('parantion/template/viewer/getFormOfNoNamedUser', { userObject: response.data, token: token, db: db })
          await this.setForm(response.data)// set form
        }
    },

    async setFormByTicket (ticket) {
      this.ticket = ticket
      const vm = this
      const result = await vm.$store.dispatch('parantion/template/viewer/authenticateTicket', { ticket: ticket })
      console.log('result', result)
      const messages = await this.decodeMessages(result.message)
      console.log('messages', messages)
      if (messages.length >= 1) {
          this.decodedMessages = messages
          this.isLoading = false
      } else {
        if (await this.checkLoginAction(result.action)){
          this.$store.dispatch('parantion/template/viewer/getTheUser', { ticket: ticket }).then(response => {
            this.checkFormDate(response.data.form)
          })
        }
      }
    },

    async setFormById (id) {
      await this.authenticateUser({ id: id })
    },

    async setFormByHash (formHash) {
      await this.authenticateUser({ formHash: formHash })
    },

    async checkLoginAction (action) {
      console.log('action', action)
      switch (action) {
        case 'continue':
          this.showIsEmptyMessage = false
          this.isLoading = false
          this.retryCount = null
          return true
        case 'viewer':
          this.showIsEmptyMessage = false
          this.isLoading = false
          this.retryCount = null
          return true
        case 'login':
          this.showIsEmptyMessage = false
          this.isLoading = false
          this.retryCount = null
          break
        case 'access':
          this.pinDialogTitle = this.$t('formviewer.pin.get_pin_title.001')
          this.pinDialogMessage = this.$t('formviewer.pin.get_pin_message.001')
          this.pinDialogAction = action
          this.showAskPin = true
          this.pin = ''
          break
        case 'refuse':
          this.pinDialogTitle = this.$t('formviewer.pin.wrong_pin_title.001')
          this.pinDialogMessage = this.$t('formviewer.pin.wrong_pin_message.001')
          this.pinDialogAction = action
          this.showAskPin = true
          this.pin = ''
          break
        case 'request':
          this.pinDialogTitle = this.$t('formviewer.pin.set_pin_title.001')
          this.pinDialogMessage = this.$t('formviewer.pin.set_pin_message.001')
          this.pinDialogAction = action
          this.disablePinInput = false
          this.showAskPin = true
          this.retryCount = null
          this.pin = ''
         break
        case 'retrymax':
          this.pinDialogTitle = this.$t('formviewer.pin.max_pin_title.001')
          this.pinDialogMessage = this.$t('formviewer.pin.max_pin_message.001')
          this.pinDialogAction = action
          this.showAskPin = true
          this.disablePinInput = true
          this.showIsEmptyMessage = false
          this.isLoading = false
          this.retryCount = null
          this.pin = ''
          break
        case 'retrymax_done':
          this.pinDialogTitle = this.$t('formviewer.pin.retrymax_done_title.001')
          this.pinDialogMessage = this.$t('formviewer.pin.retrymax_done.001')
          this.pinDialogAction = 'request'
          this.showAskPin = true
          this.disablePinInput = true
          this.showIsEmptyMessage = false
          this.isLoading = false
          this.retryCount = null
          this.pin = ''
          break
        case 'stop':
          this.pinDialogTitle = this.$t('formviewer.pin.stop_pin_title.001')
          this.pinDialogMessage = this.$t('formviewer.pin.stop_pin_message.001')
          this.pinDialogAction = action
          this.showAskPin = false
          this.disablePinInput = true
          this.showIsEmptyMessage = true
          this.isLoading = false
          this.retryCount = null
          this.pin = ''
          break
        case true:
          this.showIsEmptyMessage = false
          this.isLoading = false
          return true
        default:
          this.showIsEmptyMessage = true
          this.isLoading = false
          return false
      }
    },

    async decodeMessages (messages) {
      const priorityMessages = []
      messages.forEach(message => {
        const messageParts = message.split('.')
        let messageText = messageParts[0];
        if (messageParts.length === 2){
          messageText = messageParts[1].replace(' ', '-')
        }
        const translation = this.$t(`formviewer.error.${messageText}.message`)
        // check if translation is available
        if (translation !== `formviewer.error.${messageText}.message`) {
          priorityMessages.push(translation)
        }
      })
      return priorityMessages
    },

    async authenticateUser(params) {
      const result = await this.$store.dispatch('parantion/template/viewer/authenticateUser', params)
      if (result) this.setForm(result.data || result)
      else this.setForm({ action: '' })
    },

    async authenticateTicket() {
      if (this.pin && this.ticket) {
        const response = await this.$store.dispatch('parantion/template/viewer/authenticateTicket', {
          ticket: this.ticket,
          access: this.pin
        })
        console.log('response', response)

        this.showAskPin = false
        this.retryCount = response.retry_count
        if (this.retryCount === 0) {
          response.action = 'retrymax'
        }
        return this.checkLoginAction(response.action)
      }
    },

    async resetTicket() {
      this.showAskPin = false
      this.showResetPin = false
      this.retryCount = null
      const response = await this.$store.dispatch('parantion/user/resetPinCode', {
        ticketHash: this.ticket
      })

      if (response.status === 400 && response.data.message?.includes('apiAccount.lost_ticket_hash_wrong progress_done')) {
        this.checkLoginAction('retrymax_done')
      } else {
        this.setFormByTicket(this.ticket)
      }
    },

    async setForm (result) {
      if (!result) {
        // There is no form found
        this.showIsEmptyMessage = true
      }

      if (result.action === 'stop') {
        this.$store.dispatch('parantion/user/logout')

        switch (result.message[0]) {
          case FormErrors.FORM_CLOSED :
            this.formClosed = true
            break
          case FormErrors.FORM_START_DATE :
            this.showDateNotStarted = true
            break
          case FormErrors.FORM_END_DATE :
            this.showDatePassed = true
            break
          case FormErrors.GRANTED_MAX_FORM :
            this.showFormNoNamedMax = true
            break
          case FormErrors.GRANTED_MAX_SUBJECT :
            this.showSubjectNoNamedMax = true
            break
        }

        const form = this.$store.state.parantion.template.viewer.formVars
       return this.checkFormDate(form)
        } else {
        // Everything is allright, check the dates
        return this.checkFormDate(result.form)
      }
    },

    toggleDialogs () {
      this.showAskPin = !this.showAskPin
      this.showResetPin = !this.showAskPin
    },


    // async handleErrors (result) {
    //   if (result.action === 'stop') {
    //     if (result.message[0].includes('form_date_end')) {
    //       this.showDatePassed = true
    //       await this.$store.dispatch('parantion/user/logout')
    //     }
    //     if (result.message[0].includes('form_date_start')) {
    //       this.showDateNotStarted = true
    //       await this.$store.dispatch('parantion/user/logout')
    //     }
    //   } else {
    //     const form = this.$store.state.parantion.template.viewer.formVars
    //     this.checkFormDate(form)
    //   }
    // },

    checkFormDate (form) {
      const today = new Date()
      if (form?.frm_date_end && new Date(form.frm_date_end) < today) {
        this.$store.dispatch('parantion/user/logout')
        this.endDate = dateUtils.dateFormat(new Date(form.frm_date_end), 'dd MMMM yyyy')
        this.showDatePassed = true
        return false
      } else if (form?.frm_date_start && new Date(form.frm_date_start) > today) {
        this.$store.dispatch('parantion/user/logout')
        this.startDate = dateUtils.dateFormat(new Date(form.frm_date_start), 'dd MMMM yyyy')
        this.showDateNotStarted = true
        return false
      }
      return true
    },

    getFormStatus () {
      if (this.$store.state.parantion.template.viewer.formVars.frm_status === 0) {
        this.$store.dispatch('parantion/user/logout')
        this.formClosed = true
      }
    }
  }
}
